import React from "react"
import { Link } from "gatsby"
import { Button, Container } from "react-bootstrap"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import * as styles from "../sections/features.module.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página não encontrada" />
    <Container fluid>
      <h3 className="mt-4 cl-identity">Página não encontrada...</h3>
      <p className>
        A página que você tentou acessar não existe, mas você pode acessar nossos serviços através das opções abaixo:
      </p>

      <div className={styles.buttonContainer} style={{maxWidth: "500px"}}>
        <Button as={Link} to={"/consultoria"} className={styles.learnMoreButton}>Consultoria</Button>
        <br />
        <Button as={Link} to={"/materiais"} className={styles.learnMoreButton}>Materiais</Button>
      </div>

    </Container>
  </Layout>
)

export default NotFoundPage
